import React from "react";

import toClass from "../../helpers/toClass";
import ButtonStyle from "./ui-btn.module.css";

interface iUIBtnProps {
  className: {
    btn: string;
    text?: string;
  };
  onClick(): void;
  btnText: string;
  Icon?: any;
  dataValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Corrected type for onChange
}

const UIBtn: React.FC<iUIBtnProps> = ({
  onClick = () => {},
  className = { btn: "border-radius: 10px" },
  btnText = "",
  Icon,
  dataValue,
  onChange, // Include the onChange prop
}) => {
  return (
    <div
      onClick={onClick}
      className={toClass([ButtonStyle.btnComponent, className.btn])}
      data-value={dataValue}
    >
      {Icon && <Icon />}
      <p
        className={toClass([
          ButtonStyle.btnName,
          className?.text ? className?.text : "",
        ])}
      >
        {btnText}
      </p>
    </div>
  );
};

export default UIBtn;
