export const IMAGES = [
  "/images/IMG_8439.jpg",
  "/images/carousel1.jpg",
  "/images/side_profile.jpg",
  "/images/testa3.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
  "/images/carousel1.jpg",
  "/images/side_profile.jpg",
  "/images/testa3.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
  "/images/carousel1.jpg",
  "/images/side_profile.jpg",
  "/images/testa3.jpg",
  "/images/IMG_8439.jpg",
  "/images/IMG_8439.jpg",
];
