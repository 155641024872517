import { useCallback, useEffect, useState } from "react";
import SlideOne from "./components/slide-one";
import SlideTwo from "./components/slide-two";
import SlideFour from "./components/slide-four";
import SlideThree from "./components/slide-three";
import SlideFive from "./components/slide-five";


const CAROUSEL_CONTENTS: any = [<SlideOne/>, <SlideTwo />, <SlideThree /> , <SlideFour />, <SlideFive/>];

const useCarouselController = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const bannerAnimation = useCallback(() => {
    if (CAROUSEL_CONTENTS && CAROUSEL_CONTENTS.length > 1) {
      setSelectedIndex(
        selectedIndex >= CAROUSEL_CONTENTS.length - 1 ? 0 : selectedIndex + 1
      );
    }
  }, [selectedIndex]);

  useEffect(() => {
    const interval = setInterval(bannerAnimation, 10000);

    return () => clearInterval(interval);
  }, [bannerAnimation]);

  const handleOnClickSteps = (index: number) => {
    setSelectedIndex(index);
  };

  return { selectedIndex, CAROUSEL_CONTENTS, handleOnClickSteps };
};

export default useCarouselController;
