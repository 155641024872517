import React from "react";
import FooterView from "./footer-view";
import useFooterController from "./footer-controller";

const Footer: React.FC<{}> = () => {
  const controller = useFooterController();
  return <FooterView {...controller} />;
};

export default Footer;
