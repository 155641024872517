import React from "react";
import UIModalView from "./ui-modal-view";

const UIModal: React.FC<iUIModalProps> = ({
  children = <></>,
  isVisible = false,
  style,
  onBackDropPress = (_: boolean) => {},
  onClickClose = () => {},
}) => {
  return (
    <UIModalView
      children={children}
      isVisible={isVisible}
      style={style}
      onBackDropPress={onBackDropPress}
      onClickClose={onClickClose}
    />
  );
};

export default UIModal;
