import React, { useEffect, useState } from "react";

import { IMAGES } from "../../resources/constants/images";
import UIModal from "../../widgets/ui-modal";
import styles from "./gallery-modal-component.module.css";
import RightChevronIcon from "../../resources/icons/right-chevron-icon";
import LeftChevronIcon from "../../resources/icons/left-chevron-icon";

const GalleryModalComponentView: React.FC<any> = ({
  selectedIndex = 0,
  isVisible = false,
  handleCloseToModal = () => {},
}) => {
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedSlideIndex(selectedIndex);
  }, [selectedIndex]);

  const handlePrev = (event: any) => {
    event?.stopPropagation();
    if (selectedSlideIndex === 0) {
      return;
    }

    setSelectedSlideIndex((prev) => prev - 1);
  };

  const handleNext = (event: any) => {
    event?.stopPropagation();
    if (selectedSlideIndex === IMAGES.length - 1) {
      return;
    }
    setSelectedSlideIndex((prev) => prev + 1);
  };

  const isGalleryHasLengthGreaterThanZero = () => {
    return IMAGES.length > 1;
  };

  return (
    <UIModal
      isVisible={isVisible}
      onBackDropPress={handleCloseToModal}
      style={{ width: "70%", height: "70%", maxHeight: "70%" }}
    >
      <>
        <div className={styles.imageComponent}>
          {isGalleryHasLengthGreaterThanZero() && selectedSlideIndex !== 0 && (
            <div className={styles.PrevIcon} onClick={handlePrev}>
              <LeftChevronIcon width={30} height={30} color="#fff" />
            </div>
          )}

          <img
            src={IMAGES[selectedSlideIndex]}
            width="100%"
            height="100%"
            alt={`galleryImage-${selectedSlideIndex}`}
          />
          {isGalleryHasLengthGreaterThanZero() &&
            selectedSlideIndex !== IMAGES.length - 1 && (
              <div className={styles.nextIcon} onClick={handleNext}>
                <RightChevronIcon width={30} height={30} color="#fff" />
              </div>
            )}
        </div>
        <div className={styles.gallerySliderComponent}>
          <div className={styles.gallerySliderScrollComponent}>
            {IMAGES &&
              IMAGES?.map((item: string, index: number) => {
                return (
                  <div
                    key={index}
                    className={
                      selectedSlideIndex === index
                        ? styles.selectedGallerySliderCardComponent
                        : styles.gallerySliderCardComponent
                    }
                    onClick={() => setSelectedSlideIndex(index)}
                  >
                    <img
                      src={item}
                      width="100%"
                      height="100%"
                      alt={`galleryImage-${index}`}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </>
    </UIModal>
  );
};

export default GalleryModalComponentView;
