import React from "react";
import CarouselView from "./carousel-view";
import useCarouselController from "./carousel-controller";

const Carousel: React.FC<{}> = () => {
    const controller = useCarouselController();
  return <CarouselView {...controller} />;
};

export default Carousel;
