import React from "react";
import slideThreeStyle from "./slide-three.module.css";
import toClass from "../../../../../../helpers/toClass";
import RightArrowIcon from "../../../../../../resources/icons/right-arrow-icon";
import UIBtn from "../../../../../../widgets/ui-btn";

const SlideThreeView: React.FC<{}> = () => {
  return (
    <div className={toClass([slideThreeStyle.slideTwoComponent])}>
      <div
        className={toClass([
          "container-md",
          slideThreeStyle.slideTwoInnerComponent,
        ])}
      >
        <div className={slideThreeStyle.slideTwoTitleComponent}>
          <h1 className={slideThreeStyle.slideTwoTitle}>
            Line are not written without reason into the human hand, everything
            is in your hands
          </h1>
          <p className={slideThreeStyle.slideTwoSubIntro}>
            Delve into the secrets held within your palms through palmistry, an
            art that reveals insights about your life's journey and potential.
          </p>
          <div>
            <a className={slideThreeStyle.slideTwoBtn} href="#appointment">
              <UIBtn
                btnText="Appointment"
                Icon={() => (
                  <div className={slideThreeStyle.slideTwoBtnIcon}>
                    <RightArrowIcon />
                  </div>
                )}
                onClick={() => {}}
                className={{ btn: slideThreeStyle.slideTwoBtnIconComponent }}
              />
            </a>
          </div>
        </div>
        <div className={slideThreeStyle.slideTwoImageComponent}>
          <div className={slideThreeStyle.slideTwoInnerOuterComponent}>
            <div className={slideThreeStyle.rotatingPalmImage}>
              <img
                src="/images/four.png"
                width="100%"
                height="100%"
                alt="palm rotating"
              />
            </div>
            {/* <div className={slideThreeStyle.handImageComponent}>
              <img
                src="/images/four.png"
                width="100%"
                height="100%"
                alt="palm hand"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideThreeView;
