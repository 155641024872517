import React from "react";

import footerStyles from "./footer.module.css";
import useMediaQuery from "../../helpers/useMediaQuery";
import UIBtn from "../../widgets/ui-btn";
import EmailIcon from "../../resources/icons/email-icon";
import PhoneIcon from "../../resources/icons/phone-icon";
import { Link } from "react-router-dom";
import InstagramIcon from "../../resources/icons/instagram-icon";
import FacebookIcon from "../../resources/icons/facebook-icon";
import TwitterIcon from "../../resources/icons/twitter-icon";
import YoutubeIcon from "../../resources/icons/youtube-icon";
import { IMAGES } from "../../resources/constants/images";
import GalleryModalComponent from "../gallery-modal-component";

const FooterLayout = ({ children }: any) => {
  const isMobile = useMediaQuery(700);
  const isTab = useMediaQuery(980);
  return (
    <div className={footerStyles.footerRootComponent}>
      <div
        className={isMobile || isTab ? "container-lg" : "container-md"}
        style={{ zIndex: 1 }}
      >
        {children}
      </div>
    </div>
  );
};

const FooterView: React.FC<any> = ({
  selectedImageIndex = 0,
  isModalVisible = false,
  handleModalVisible = (_: boolean) => {},
  handleFooterGallery = () => {},
}) => {
  const isMobile = useMediaQuery(720);

  const TopFooterComponent = () => {
    return (
      <div className={footerStyles.footer_top_component}>
        {/*TODO: <div className={footerStyles.footer_overlay} />  -- when we will have video or any image then un comment this */}
        <FooterLayout>
          <div className={footerStyles.footer_top_text_component}>
            <div>
              <h1 className={footerStyles.footer_top_text_component_heading}>
                Join the exciting world of yoga and wellness by subscribing to
                our{" "}
                <span className={"globalLinearGradientTextStyle"}>
                  weekly newsletter today
                </span>
              </h1>
            </div>
            <div className={footerStyles.footer_bottom_input_component}>
              <div>
                <div className={footerStyles.footerInputComponent}>
                  <input
                    type="email"
                    placeholder="Email"
                    value=""
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div>
                <UIBtn
                  btnText="Subscribe"
                  onClick={() => {}}
                  className={{ btn: footerStyles.footer_subscribe_btn }}
                />
              </div>
            </div>
          </div>
        </FooterLayout>
      </div>
    );
  };

  const CompanyProfile = () => {
    return (
      <div>
        <div className={footerStyles.companyProfileLogoComponent}>
          <div className={footerStyles.companyProfileLogo}>
            <img
              src="/astro-yoga-logo.png"
              width="100%"
              height="100%"
              alt="logo"
            />
          </div>
          <p>Adhyatma Yoga</p>
        </div>
        <div className={footerStyles.icon_component}>
          <EmailIcon width={20} height={20} color={"var(--color-dark-green)"} />
          <a href="mailto::jamunamishra@gmail.com">jamunamishra@gmail.com</a>
        </div>
        <div className={footerStyles.icon_component}>
          <PhoneIcon width={20} height={20} color={"var(--color-dark-green)"} />
          <a href="tel:+393334939973">+393334939973</a>
        </div>
        <div className={footerStyles.icon_component}>
          <PhoneIcon width={20} height={20} color={"var(--color-dark-green)"} />
          <a href="tel:+919425143823">+91-9425143823</a>
        </div>
      </div>
    );
  };

  const Services = () => {
    return (
      <div className={footerStyles.footer_services_component}>
        <h3>Classes</h3>
        <Link to="/">Lorem ipsum</Link>
        <Link to="/">Lorem Ipsum</Link>
        <Link to="/">Lorem Ipsum</Link>
        <Link to="/">Lorem Ipsum</Link>
      </div>
    );
  };

  const UtilityPages = () => {
    return (
      <div className={footerStyles.footer_services_component}>
        <h3>Utility Pages</h3>
        <a href="/#about-us">About Us</a>
        <a href="/#services">Service</a>
        <a href="/#youtube">Videos</a>
        <a href="/gallery">Gallery</a>
      </div>
    );
  };

  const OurGallery = () => {
    const images = isMobile
      ? IMAGES.slice(0, 4)?.map((item: string, index) => ({
          className: `footer_gallery_image_${index + 1}`,
          img: item,
        }))
      : IMAGES?.slice(0, 7)?.map((item: string, index) => ({
          className: `footer_gallery_image_${index + 1}`,
          img: item,
        }));
    return (
      <div className={footerStyles.footer_bottom_gallery_component}>
        <h3>Gallery</h3>
        <div className={footerStyles.footer_gallery_component}>
          {images &&
            images?.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={item.className}
                  onClick={() => handleFooterGallery(index)}
                >
                  <img
                    src={item?.img}
                    width="100%"
                    height="100%"
                    alt={`galleryImage-${index + 1}`}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const BottomFooterComponent = () => {
    return (
      <FooterLayout>
        <div className={footerStyles.bottom_footer_component}>
          <CompanyProfile />
          {/* <Services /> */}
          <UtilityPages />
          <OurGallery />
        </div>
      </FooterLayout>
    );
  };

  const CopyRightComponent = () => {
    return (
      <FooterLayout>
        <div className={footerStyles.footer_copyRightComponent}>
          <div>
            <p>&#169;2023 - Adhyatma Yoga</p>
          </div>
          <div className={footerStyles.footer_social_icon_component}>
            <div>
              <a
                href="https://www.instagram.com/jamunamishraguruji/"
                target="_blank"
                className={footerStyles.footer_social_individual_icon_component}
                rel="noreferrer"
              >
                <InstagramIcon width={20} height={20} color="#fff" />
              </a>
            </div>
            <div>
              <a
                href="https://www.facebook.com/jamunamishra"
                target="_blank"
                className={footerStyles.footer_social_individual_icon_component}
                rel="noreferrer"
              >
                <FacebookIcon width={20} height={20} color="#fff" />
              </a>
            </div>
            <div>
              <a
                href="https://twitter.com/jamunamishra?s=20"
                target="_blank"
                className={footerStyles.footer_social_individual_icon_component}
                rel="noreferrer"
              >
                <TwitterIcon width={20} height={20} color="#fff" />
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/channel/UCq0s0fsPfBxczyMsYGMRkVw"
                target="_blank"
                className={footerStyles.footer_social_individual_icon_component}
                rel="noreferrer"
              >
                <YoutubeIcon width={20} height={20} color="#fff" />
              </a>
            </div>
          </div>
        </div>
      </FooterLayout>
    );
  };
  return (
    <>
      <div>
        <TopFooterComponent />
        <div className={footerStyles.footer_lastTwo_root_component}>
          <BottomFooterComponent />
          <CopyRightComponent />
        </div>
      </div>
      <GalleryModalComponent
        selectedIndex={selectedImageIndex}
        isVisible={isModalVisible}
        handleCloseToModal={handleModalVisible}
      />
    </>
  );
};

export default FooterView;
