import React, { FC } from "react";
import HomePageView from "./home-page-view";

interface HomePageModelProps {
  onServiceSelect: (serviceTitle: string) => void;
}

const HomePageModel: FC<HomePageModelProps> = ({ onServiceSelect }) => {
  return <HomePageView onServiceSelect={onServiceSelect} />;
};

export default HomePageModel;