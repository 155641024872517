import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';

import error404Styles from "./404.module.css";
import UIBtn from "../../widgets/ui-btn";
import toClass from "../../helpers/toClass";
import { useCallback } from "react";

const Error404 = () => {

  const navigate = useNavigate();

  const Routes = useCallback(() => {
    return (
      <ul className={error404Styles.routesLinksComponent}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>/</li>
        <li>Error 404</li>
      </ul>
    );
  }, []);

  const TopComponent = useCallback(() => {
    return (
      <div className={error404Styles.headerComponent}>
        <div className={error404Styles.headerRootComponent}></div>
        <div className="container-md">
          <Routes />
          <h1 className={error404Styles.notFoundText}>Not Found</h1>
        </div>
      </div>
    );
  }, [Routes]);

  const IntroComponent = useCallback(() => {
    return (
      <div className={error404Styles.introRootComponent}>
        <h1 className={error404Styles.text404}>404</h1>
        <div className="divider">
          <div className={error404Styles.innerDivider}></div>
        </div>
        <p className={error404Styles.failureText}>
          Oops! It could be either you or us - there is no page here. <br /> It
          might have been moved or deleted
        </p>

        <UIBtn
          btnText="Go To Home"
          onClick={() => navigate("/")}
          className={{
            btn: toClass(["backGroundPrimaryColor",error404Styles.homeButton]),
          }}
        />
      </div>
    );
  }, [navigate]);

  return (
    <div>
      <TopComponent />
      <IntroComponent />
    </div>
  );
};

export default Error404;
