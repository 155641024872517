import React from "react";

import slideFiveStyle from "./slide-five.module.css";
import toClass from "../../../../../../helpers/toClass";
import RightArrowIcon from "../../../../../../resources/icons/right-arrow-icon";
import UIBtn from "../../../../../../widgets/ui-btn";


const SlideFiveView: React.FC<{}> = () => {
  return (
    <div className={toClass([slideFiveStyle.slideOneComponent])}>
      <div
        className={toClass([
          "container-md",
          slideFiveStyle.slideOneInnerComponent,
        ])}
      >
        <div className={slideFiveStyle.slideOneTitleComponent}>
          <h1 className={slideFiveStyle.slideOneTitle}>Feel The Herbal Life</h1>
          <p className={slideFiveStyle.slideOneSubIntro}>
            Discover the wisdom of Ayurveda, a holistic healing system that
            balances your unique constitution for optimal health and vitality.
          </p>

          <div>
            <a className={slideFiveStyle.slideOneBtn} href="#appointment">
              <UIBtn
                btnText="Appointment"
                Icon={() => (
                  <div className={slideFiveStyle.slideOneBtnIcon}>
                    <RightArrowIcon />
                  </div>
                )}
                onClick={() => {}}
                className={{ btn: slideFiveStyle.slideOneBtnIconComponent }}
              />
            </a>
          </div>
        </div>
        <div className={slideFiveStyle.slideOneImageComponent}>
          <div className={slideFiveStyle.slideOneInnerOuterComponent}>
            <div className={slideFiveStyle.slideOneImageInnerComponent}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideFiveView;
