import React from "react"
import UserContext from "./UserContext"

const UserContexProvider = ({children}) =>{
    const [userSelect, setUserSelect] = React.useState(null)
    const [selectedService, setSelectedService] = React.useState(''); 
    console.log("userSelect:", userSelect);

    return(
        <UserContext.Provider value={{ userSelect, setUserSelect ,  selectedService, setSelectedService}}>
  {children}
</UserContext.Provider>
    )

}

export default UserContexProvider