import { FC, lazy, Suspense } from "react";

import Carousel from "./components/carousel";
import UIModal from "../../widgets/ui-modal";
import Appointment from "../appointment"
import UserContexProvider from "../../helpers/userContextProvider";

// import AboutUsComponent from "../../components/about-us-component";
// import WorshipComponent from "./components/worship-omponent";
// import JoinUsComponent from "./components/join-us-compnent";
// import ServiceView from "./components/service";
// import UpcomingVideoComponent from "./components/upcoming-video-component";
// import TrainerComponent from "../../components/trainer-component";
// import Publication from "./components/publication";

const AboutUsComponent = lazy(
  () => import("../../components/about-us-component")
);
const WorshipComponent = lazy(() => import("./components/worship-omponent"));
const JoinUsComponent = lazy(() => import("./components/join-us-compnent"));
const ServiceView = lazy(() => import("./components/service"));
const UpcomingVideoComponent = lazy(
  () => import("./components/upcoming-video-component")
);
const TrainerComponent = lazy(
  () => import("../../components/trainer-component")
);
const Publication = lazy(() => import("./components/publication"));
const Playlist = lazy(() => import("./components/playlist"));

const HomePageView: FC<{ onServiceSelect: (serviceTitle: string) => void }> = ({ onServiceSelect }) => {
  return (
    <>
      <Carousel />
      <Suspense fallback={<></>}>
        <AboutUsComponent />
      </Suspense>
      <Suspense fallback={<></>}>
        <WorshipComponent />
      </Suspense>
      <Suspense fallback={<></>}>
        <JoinUsComponent />
      </Suspense>
      <Suspense fallback={<></>}>
        <ServiceView />
      </Suspense>
      <Suspense fallback={<></>}>
        <UpcomingVideoComponent />
      </Suspense>
      {/* <Suspense fallback={<></>}>
        <TrainerComponent />
      </Suspense> */}
      <Suspense fallback={<></>}>
        <Publication />
      </Suspense>
      <Suspense fallback={<></>}>
        <Playlist />
      </Suspense>
      <Suspense fallback={<></>}>
        
      <div id="appointment-section">
             <Appointment  onServiceSelect={onServiceSelect}/>
             </div>
            
      </Suspense>
    </>
  );
};

export default HomePageView;
