import React from "react";

const LeftChevronIcon: React.FC<iIcon> = ({
  width = 20,
  height = 20,
  color = "#000",
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      id="left-arrow"
    >
      <path
        d="m8.5 12.8 5.7 5.6c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-4.9-5 4.9-5c.4-.4.4-1 0-1.4-.2-.2-.4-.3-.7-.3-.3 0-.5.1-.7.3l-5.7 5.6c-.4.5-.4 1.1 0 1.6 0-.1 0-.1 0 0z"
        fill={color}
      ></path>
    </svg>
  );
};

export default LeftChevronIcon;
