import React from "react";
import GalleryView from "./gallery-view";
import useGalleryController from "./gallery-controller";

const Gallery: React.FC<{}> = () => {
  const controller = useGalleryController();
  return <GalleryView {...controller} />;
};

export default Gallery;
