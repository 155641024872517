import { FC } from "react";
import NavbarView from "./navbar-view";
import useNavbarController from "./navbar-controller";

const NavbarModel: FC<{}> = () => {
  const controller = useNavbarController()
  return <NavbarView {...controller}  />;
};

export default NavbarModel;
