import React from 'react';
import styles from './appointment.module.css'; // Import the CSS module


interface AppointmentViewProps {
    selectedService: string | undefined;
    setSelectedService: React.Dispatch<React.SetStateAction<string | undefined>>;
    onServiceSelect: (serviceTitle: string) => void;
    handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    selectedValue: string | undefined;
  }

const AppointmentView: React.FC<AppointmentViewProps> = ({
    selectedService,
    setSelectedService,
    onServiceSelect,
    handleSelectChange,
    selectedValue,
}) => {
  const services = [
    'yoga',
    'Ayurveda',
    'Palmistry',
    'Chakra Healing',
    'Spiritual Education',
    'Acupressure',
  ];

  // Rest of your component code goes here...

  return (
    <>
    <div id="appointment" className={styles.headingAppointment}>
        <h1 className={styles.headingone}>
        Schedule Your <span className={styles.headingtwo}>Appointment</span>
        </h1>
      </div>
      <div className="divider">
        <div className={styles.innerDivider} />
      </div>

      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.mainHeading}></div>
          <div className={styles.formContainer}>
            <label htmlFor="serviceSelect" className={styles.formLabel}>
              Select a Service:
            </label>
            <select
              id="serviceSelect"
              onChange={handleSelectChange} value={  selectedService||selectedValue  }
              className={styles.selectInput}
            >
              <option value="">-- Select a service --</option>
              {services.map((service, index) => (
                <option key={index} value={service} className={styles.select}>
                  {service}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.formLabelService}>
            <p><span className={styles.formLabelSpan}>Selected Service   - : </span>
              {selectedService
                ? `${selectedService}`
                : `${selectedValue}`}
            </p>
          </div>
        </div>

      
      <div className={styles.contentContainer}>
        <div className={styles.grid}>
          <div className={styles.flex}>
         
            <form action="" className={styles.form}>
              
              <div className={styles.grid2}>
                <div className={styles.gridItem}>
                  <label htmlFor="first_name" className={styles.formLabel}>
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    placeholder="First Name"
                    className={styles.formInput}
                  />
                </div>
                <div className={styles.gridItem}>
                  <label htmlFor="last_name" className={styles.formLabel}>
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    placeholder="Last Name"
                    className={styles.formInput}
                  />
                </div>
              </div>
              <div className={styles.gridItem}>
                <label htmlFor="email" className={styles.formLabel}>
                  Email
                </label>
                <input
                  type="text"
                  id="email"
                  placeholder="Email"
                  className={styles.formInput}
                />
              </div>
              <div className={styles.gridItem}>
                <label htmlFor="phone_number" className={styles.formLabel}>
                  Phone number
                </label>
                <input
                  type="tel"
                  id="phone_number"
                  placeholder="Phone number"
                  className={styles.formInput}
                />
              </div>
              <div className={styles.gridItem}>
                <label htmlFor="message" className={styles.formLabel}>
                  Message
                </label>
                <input
                  id="message"
                  placeholder="Leave us a message"
                  
                  className={styles.formTextarea}
                ></input>
              </div>
              <button
                type="button"
                className={styles.submitButton}
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      <hr className={styles.hr} />
      </div>
    </>
  );
};

export default AppointmentView;
