import React from "react";

const RightChevronIcon: React.FC<iIcon> = ({
  width = 20,
  height = 20,
  color = "#000",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      id="right-arrow"
      width={width}
      height={height}
      fill={color}
    >
      <path
        d="M15.5,11.3L9.9,5.6c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.9,4.9l-4.9,4.9c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.6,0.4,1,1,1
	c0.3,0,0.5-0.1,0.7-0.3l5.7-5.7c0,0,0,0,0,0C15.9,12.3,15.9,11.7,15.5,11.3z"
        fill={color}
      ></path>
    </svg>
  );
};

export default RightChevronIcon;
