import React, { useContext, useState } from 'react';
import UserContext from '../../helpers/UserContext';
import AppointmentView from './appoinment-view'

const AppointmentController: React.FC = () => {
  const { selectedService } = useContext(UserContext);
  const [selectedValue, setSelectedValue] = useState<string | undefined>('Select a service');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
  };

  const onServiceSelect = (serviceTitle: string) => {
    // Implement your logic here to handle the service selection
  };

  return (
    <AppointmentView
      selectedService={selectedService}
      setSelectedService={setSelectedValue}
      onServiceSelect={onServiceSelect}
      handleSelectChange={handleSelectChange}
      selectedValue={selectedValue}
    />
  );
};

export default AppointmentController;
