import './globals.css';
import React, { useState } from 'react';
import AppRouter from "./utils/app-routes";
import UserContexProvider from './helpers/userContextProvider';

const App: React.FC = () => {
  const [selectedService, setSelectedService] = useState<string>('');

  const onServiceSelect = (serviceTitle: string) => {
    setSelectedService(serviceTitle);
  };

  return (
    <div className="App">
      <UserContexProvider>
      <AppRouter onServiceSelect={onServiceSelect} />
      </UserContexProvider>
    </div>
  );
}

export default App;
