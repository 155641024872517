import {useState} from 'react';

const useGalleryController = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleFooterGallery = (index: number) => {
    setSelectedImageIndex(index);
    setIsModalVisible(true);
  };

  const handleModalVisible = (cond: boolean) => {
    setIsModalVisible(cond);
  };

  return {
    selectedImageIndex,
    isModalVisible,
    handleFooterGallery,
    handleModalVisible,
  };
};

export default useGalleryController;
