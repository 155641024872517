import { useState } from "react";
import { useLocation,  useNavigate  } from "react-router-dom";

const useNavbarController = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>();

  const location = useLocation();

  const currentActivePath = location.pathname;

  const toggleMobileMenu = (): void => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const navigate = useNavigate();

 
  const handleNavigateToappointments = () => {
    const appointmentSection = document.getElementById('appointment-section');
    if (appointmentSection) {
      appointmentSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return { currentActivePath, isMobileMenuOpen, toggleMobileMenu,  handleNavigateToappointments, navigate };
  
};

export default useNavbarController;
