import React from "react";

import modalStyles from "./ui-modal.module.css";

const UIModalView: React.FC<iUIModalViewProps> = ({
  children = <></>,
  style = { width: "50%", height: "50%" },
  isVisible = false,
  onBackDropPress = (_: boolean) => {},
  onClickClose = () => {},
}) => {
  const handleBackDropPropagation = (e: any) => {
    e?.stopPropagation();
    onBackDropPress(false);
  };
  return (
    <>
      {isVisible && (
        <div
          className={modalStyles.rootModalOverLayComponent}
          onClick={handleBackDropPropagation}
        >
          <div
            className={modalStyles.modalContainer}
            onClick={(e: any) => e?.stopPropagation()}
            style={style}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default UIModalView;
