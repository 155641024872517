import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePageModel from "../pages/home-page";
import AppLayout from "./app-layout";
import AppError from "./error-layout";
import Gallery from "../pages/gallery";
import Appointment from "../pages/appointment";

interface AppRouterProps {
  onServiceSelect: (serviceTitle: string) => void;
}

const AppRouter: React.FC<AppRouterProps> = ({ onServiceSelect }) => {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      errorElement: <AppError />,
      children: [
        {
          path: "/",
          element: <HomePageModel onServiceSelect={onServiceSelect} />,
        },
        {
          path: "/gallery",
          element: <Gallery />,
        },
        {
          path: "/appointment:1",
          element: <Appointment onServiceSelect={onServiceSelect} />,
        },
      ],
    },
  ]);

  return <RouterProvider router={appRouter} />;
};

export default AppRouter;
