import { FC, useCallback } from "react";

import { NavLink } from "react-router-dom";
import navbarStyle from "./navbar.module.css";
import toClass from "../../helpers/toClass";
import UIBtn from "../../widgets/ui-btn";
import useMediaQuery from "../../helpers/useMediaQuery";
import MenuIcon from "../../resources/icons/menu-icon";
import CloseIcon from "../../resources/icons/close-icon";

const NAV_LINKS: iNavLinksSchema[] = [
  {
    name: "Home",
    path: "/",
  href:"/",
},
  {
    name: "About Us",
    path: "/aboutus",
    href:"/#about-us",
 
},
  {
    name: "Services",
    path: "/services",
  href:"/#services",
},
  {
    name: "Blogs",
    path: "/blogs",
    href:"",
},
  {
    name: "Gallery",
    path: "/gallery",
    href:"",
},
];

const NavbarView: FC<iNavbarViewProps> = ({
  currentActivePath = "/",
  isMobileMenuOpen = false,
  toggleMobileMenu = () => {},
  handleNavigateToappointments = () => {},
}) => {
  const isMobile: boolean = useMediaQuery(720);

  const NavbarLeftComponent = useCallback(
    () => (
      <div className={navbarStyle.navLogoComponent}>
        <img
          src="/astro-yoga-logo.png"
          alt="Astro Yoga logo"
          width={"100%"}
          height={"100%"}
        />
      </div>
    ),
    []
  );

  const NavbarLinks = useCallback(
    ({
      inActiveClassName,
      activeClassName,
      activeLinkOuterComponentStyle,
    }: any) => (
      <>
        {NAV_LINKS &&
          NAV_LINKS?.map((navItem: iNavLinksSchema) => (
            <div
              key={navItem?.name}
              className={
                activeLinkOuterComponentStyle
                  ? currentActivePath === navItem?.path
                    ? toClass([
                        navbarStyle.linkComponent,
                        activeLinkOuterComponentStyle,
                      ])
                    : navbarStyle.linkInActiveOuterComponent
                  : navbarStyle.linkComponent
              }
            >{ navItem.href ?
              <a
                className={
                  currentActivePath === navItem?.href
                    ? activeClassName
                    : inActiveClassName
                }
                href={navItem?.href || "/"}
              >
                {navItem?.name || ""}
              </a>:
              <NavLink
                className={
                  currentActivePath === navItem?.path
                    ? activeClassName
                    : inActiveClassName
                }
                to={navItem?.path || "/"}
              >
                {navItem?.name || ""}
              </NavLink>}
            </div>
          ))}
      </>
    ),
    [currentActivePath]
  );

  const NavbarWebRightComponent = useCallback(() => {
    return (
      <div className={navbarStyle.navLinksComponent}>
        <NavbarLinks
          inActiveClassName={navbarStyle.navLinkCommonStyle}
          activeClassName={toClass([
            navbarStyle.navLinkCommonStyle,
            navbarStyle.navActiveLinkStyle,
          ])}
        />
        <UIBtn
          btnText="Appointments"
          
          className={{ btn: navbarStyle.appointmentButton }}
          onClick={handleNavigateToappointments}
        />
      </div>
    );
  }, [NavbarLinks]);

  const NavMobileRightComponent = useCallback(() => {
    const IconProperties = {
      color: "#000",
      width: 30,
      height: 30,
    };

    return (
      <div className={navbarStyle.navMobileLinksComponent}>
        <div onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? (
            <CloseIcon {...IconProperties} />
          ) : (
            <MenuIcon {...IconProperties} />
          )}
        </div>
        {isMobileMenuOpen && (
          <div className={navbarStyle.navMobileMenuDropdown}>
            <NavbarLinks
              inActiveClassName={navbarStyle.navMobileLinkCommonStyle}
              activeClassName={toClass([
                navbarStyle.navMobileLinkCommonStyle,
                navbarStyle.navMobileActiveCommonStyle,
              ])}
              activeLinkOuterComponentStyle={
                navbarStyle.navMobileActiveLinkOuterComponent
              }
            />
            <UIBtn
              btnText="Appointment"
              className={{ btn: navbarStyle.mobileAppointButton }}
              onClick={handleNavigateToappointments}
            />
          </div>
        )}
      </div>
    );
  }, [NavbarLinks, isMobileMenuOpen, toggleMobileMenu, handleNavigateToappointments]);

  const NavbarRightComponent = useCallback(
    () =>
      !isMobile ? <NavbarWebRightComponent /> : <NavMobileRightComponent />,
    [isMobile, NavbarWebRightComponent, NavMobileRightComponent]
  );

  return (
    <nav
      className={
        isMobile ? navbarStyle.navMobileComponent : navbarStyle.navWebComponent
      }
    >
      <NavbarLeftComponent />
      <NavbarRightComponent />
     </nav>
  );
};

export default NavbarView;
