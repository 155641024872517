import React from "react";
import slideFourStyle from "./slide-four.module.css";
import toClass from "../../../../../../helpers/toClass";
import RightArrowIcon from "../../../../../../resources/icons/right-arrow-icon";
import UIBtn from "../../../../../../widgets/ui-btn";

const SlideFourView: React.FC<{}> = () => {
  return (
    <div className={toClass([slideFourStyle.slideTwoComponent])}>
      <div
        className={toClass([
          "container-md",
          slideFourStyle.slideTwoInnerComponent,
        ])}
      >
        <div className={slideFourStyle.slideTwoTitleComponent}>
          <h1 className={slideFourStyle.slideTwoTitle}>
            You are never alone eternally connected with everyone
          </h1>
          <p className={slideFourStyle.slideTwoSubIntro}>
            Experience the transformative power of chakra healing, <br /> a
            practice that aligns and harmonizes your body's energy centers for
            spiritual and emotional well-being.
          </p>

          <div>
            <a className={slideFourStyle.slideTwoBtn} href="#appointment">
              <UIBtn
                btnText="Appointment"
                Icon={() => (
                  <div className={slideFourStyle.slideTwoBtnIcon}>
                    <RightArrowIcon />
                  </div>
                )}
                onClick={() => {}}
                className={{ btn: slideFourStyle.slideTwoBtnIconComponent }}
              />
            </a>
          </div>
        </div>
        <div className={slideFourStyle.slideTwoImageComponent}>
          <div className={slideFourStyle.slideTwoInnerOuterComponent}>
            <div className={slideFourStyle.rotatingPalmImage}>
              <img
                src="/images/three.png"
                width="100%"
                height="100%"
                alt="palm rotating"
              />
            </div>
            {/* <div className={slideFourStyle.handImageComponent}>
              <img
                src="/images/three.png"
                width="150%"
                height="120%"
                alt="palm hand"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideFourView;
