import React from "react";

import galleryStyles from "./gallery.module.css";
import { IMAGES } from "../../resources/constants/images";
import GalleryModalComponent from "../../components/gallery-modal-component";

interface iGalleryViewProps {
  selectedImageIndex: number;
  isModalVisible: boolean;
  handleModalVisible(cond: boolean): void;
  handleFooterGallery(index: number): void;
}

const GalleryView: React.FC<iGalleryViewProps> = ({
  selectedImageIndex = 0,
  isModalVisible = false,
  handleModalVisible = (_: boolean) => {},
  handleFooterGallery = () => {},
}) => {
  return (
    <div  id="gallery" className={galleryStyles.gallery_root_component}>
      <div className={galleryStyles.gallery}>
        {IMAGES.map((image, index) => (
          <div
            className={galleryStyles.gallery__item_component}
            onClick={() => handleFooterGallery(index)}
          >
            <img
              key={index}
              className={galleryStyles.gallery__item}
              src={image}
              alt=""
            />
          </div>
        ))}
      </div>
      <GalleryModalComponent
        selectedIndex={selectedImageIndex}
        isVisible={isModalVisible}
        handleCloseToModal={handleModalVisible}
      />
    </div>
  );
};

export default GalleryView;
