import React from "react";

import slideOneViewStyle from "./slide-one.module.css";
import toClass from "../../../../../../helpers/toClass";
import UIBtn from "../../../../../../widgets/ui-btn";
import RightArrowIcon from "../../../../../../resources/icons/right-arrow-icon";

const SlideOneView: React.FC<{}> = () => {
  return (
    <div className={toClass([slideOneViewStyle.slideOneComponent])}>
      <div
        className={toClass([
          "container-md",
          slideOneViewStyle.slideOneInnerComponent,
        ])}
      >
        <div className={slideOneViewStyle.slideOneTitleComponent}>
          {/* <h1 className={slideOneViewStyle.slideOneTitle}>
            Experience the best yoga studio in{" "}
            <span className="globalLinearGradientTextStyle">
              San Diego, CA
            </span>
          </h1> */}
          <h1 className={slideOneViewStyle.slideOneTitle}>Elevate Your Body, Mind, and Soul:
          <span className="globalLinearGradientTextStyle"> Explore the Art of Yoga</span></h1>
          <p className={slideOneViewStyle.slideOneSubIntro}>
            Come explore the amazing classes and instructors, and unlock your
            inner potential!
          </p>
          <a className={slideOneViewStyle.slideTwoBtn} href="#services">
          <UIBtn
            btnText="Explore More"
            Icon={() => (
              <div className={slideOneViewStyle.slideOneBtnIcon}>
                <RightArrowIcon />
              </div>
            )}
            onClick={() => {}}
            className={{ btn: slideOneViewStyle.slideOneBtnIconComponent }}
          /></a>
        </div>
        <div className={slideOneViewStyle.slideOneImageComponent}>
          <div className={slideOneViewStyle.slideOneInnerOuterComponent}>
            <div
              className={slideOneViewStyle.slideOneImageInnerComponent}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideOneView;
