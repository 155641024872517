import { Fragment } from "react";
import { Outlet } from "react-router-dom";

import NavbarModel from "../components/navbar";
import Footer from "../components/footer";

const AppLayout = () => {
  return (
    <Fragment>
      <NavbarModel />
      <Outlet />
      <Footer />
    </Fragment>
  );
};

export default AppLayout;
