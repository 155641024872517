import React from "react";

import carouselStyle from "./carousel.module.css";
import toClass from "../../../../helpers/toClass";

const CarouselView: React.FC<iCarouselViewProps> = ({
  selectedIndex = 0,
  CAROUSEL_CONTENTS = [],
  handleOnClickSteps = (_index: number) => {},
}) => {
  const CarouselStepsComponent = () => {
    return (
      <div className={carouselStyle.stepsRootComponent}>
        {CAROUSEL_CONTENTS.map((_item: any, index: number) => (
          <div
            key={index}
            onClick={() => handleOnClickSteps(index)}
            className={
              selectedIndex === index
                ? toClass([
                    carouselStyle.stepsCommonStyle,
                    carouselStyle.activeStepsStyle,
                  ])
                : toClass([
                    carouselStyle.stepsCommonStyle,
                    carouselStyle.inActiveStepsStyle,
                  ])
            }
          />
        ))}
      </div>
    );
  };
  return (
    <div className={carouselStyle.carouselRootComponent}>
      {CAROUSEL_CONTENTS[selectedIndex]}

      {CAROUSEL_CONTENTS?.length > 1 && <CarouselStepsComponent />}
    </div>
  );
};

export default CarouselView;
