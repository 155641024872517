import React from "react";

import slideTwoStyle from "./slide-two.module.css";
import toClass from "../../../../../../helpers/toClass";
import RightArrowIcon from "../../../../../../resources/icons/right-arrow-icon";
import UIBtn from "../../../../../../widgets/ui-btn";

const SlideTwoView: React.FC<{}> = () => {
  return (
    <div className={toClass([slideTwoStyle.slideTwoComponent])}>
      <div
        className={toClass([
          "container-md",
          slideTwoStyle.slideTwoInnerComponent,
        ])}
      >
        <div className={slideTwoStyle.slideTwoTitleComponent}>
          <h1 className={slideTwoStyle.slideTwoTitle}>Releife, from life pressure drive into accupressure</h1>
          <p className={slideTwoStyle.slideTwoSubIntro}>
          Unlock the healing benefits of acupressure, an ancient technique that stimulates key points on the body to alleviate pain and promote holistic wellness 
          </p>
          <a className={slideTwoStyle.slideTwoBtn} href="#services">

          <UIBtn
            btnText="Know More"
            Icon={() => (
              <div className={slideTwoStyle.slideTwoBtnIcon}>
                <RightArrowIcon />
              </div>
            )}
            onClick={() => {}}
            className={{ btn: slideTwoStyle.slideTwoBtnIconComponent }}
          />
          </a>
        </div>
        <div className={slideTwoStyle.slideTwoImageComponent}>
          <div className={slideTwoStyle.slideTwoInnerOuterComponent}>
            <div className={slideTwoStyle.rotatingPalmImage}>
              <img
                src="/images/Releife-01.png"
                width="100%"
                height="100%"
                alt="palm rotating"
              />
            </div>
            {/* <div className={slideTwoStyle.handImageComponent}>
              <img
                src="/images/hand1.png"
                width="50%"
                height="50%"
                alt="palm hand"
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideTwoView;
