import { FC } from "react";

interface iMenuIcon {
  width?: number;
  height?: number;
  color?: string;
}

const MenuIcon: FC<iMenuIcon> = ({
  width = 24,
  height = 24,
  color = "#fff",
}) => (
  <svg width={width} height={height} viewBox="0 0 30 30" aria-hidden="true">
    <path
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M4 7h22M4 15h22M4 23h22"
    ></path>
  </svg>
);

export default MenuIcon;
