import React from 'react';
import AppointmentController from './appointment-controller';
interface AppointmentProps {
  onServiceSelect: (serviceTitle: string) => void;
}

const Appointment: React.FC<AppointmentProps> = ({ onServiceSelect }) => {
  return (
    <>
      <AppointmentController />
    </>
  );
};

export default Appointment;
